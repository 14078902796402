import { Dropdown, Menu } from 'antd'
import { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import IconDownSVG from '@/components/icon/own-svg/el-icon-down.svg'
import ExternalLinkSVG from '@/components/icon/own-svg/el-icon-external-link.svg'
import LogoutSVG from '@/components/icon/own-svg/el-icon-log-out.svg'
import SignupSVG from '@/components/icon/own-svg/el-icon-signup.svg'
import { useIntl } from '@/i18n/hooks/use-intl'
import { logout } from '@/store/me'
import env from '@/utils/env'

const UserDropdown: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const menu = (
    <Menu>
      <Menu.Item>
        <Link href={env.APP_URL}>
          <ExternalLinkSVG />
          {formatMessage({ id: 'general:go_to_app' })}
        </Link>
      </Menu.Item>
      <Menu.Item
        onClick={(): void => {
          dispatch(logout())
        }}
      >
        <Link>
          <LogoutSVG />
          {formatMessage({ id: 'general:logout' })}
        </Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <DropdownWrapper id='user-dropdown'>
      <Dropdown
        getPopupContainer={(): HTMLElement =>
          document.getElementById('user-dropdown') ?? document.body
        }
        overlay={menu}
        overlayClassName='user-dropdown'
        trigger={['click']}
      >
        <a onClick={(e): void => e.preventDefault()}>
          <SignupSVG />
          <IconDownSVG class='dropdown' />
        </a>
      </Dropdown>
    </DropdownWrapper>
  )
}

export default UserDropdown

const DropdownWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  margin-top: -3px;

  > svg {
    margin: 0 3px;
  }

  .anticon {
    color: #3e3d3d;
    font-size: 16px;
  }

  .ant-dropdown-trigger {
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
      margin: 0 3px;
    }

    .dropdown {
      width: 12px;
      height: 7px;

      path {
        fill: #000;
      }
    }
  }

  .ant-dropdown-menu-item {
    a {
      display: flex;
      align-items: center;
      font-size: 16px;

      &:hover {
        color: #f54337;
        svg {
          fill: #f54337;
        }
      }
      svg {
        fill: #3e3d3d;
        width: 16px;
        height: 16px;
        margin: 0 8px 0 0;
      }
    }
  }

  .ant-dropdown-menu-item:hover {
    background-color: white;
  }

  .user-dropdown {
    .ant-dropdown-menu {
      padding: 16px 10px;
    }
  }
`

const Link = styled.a`
  width: max-content;
`

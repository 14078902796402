import { z } from 'zod'
import { parseDomainByHost } from '@/utils/get-domain'
import { isClient } from '@/utils/is-client'

const isDevelopment = process.env.NODE_ENV === 'development'

const mapAppUrl = (): string => {
  if (isDevelopment) {
    return 'http://localhost:3002'
  }
  return isClient() ? window.location.origin : '/'
}

const mapApiBaseUrl = (): string => {
  const subdomain = ((): string => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production') {
      return 'app'
    }
    return `app.${process.env.NEXT_PUBLIC_ENVIRONMENT}`
  })()

  const domain = ((): string => {
    if (isDevelopment) {
      return 'kolr.ai'
    }

    return isClient() ? parseDomainByHost(window.location.host) : '/'
  })()

  return `https://${subdomain}.${domain}/api` // e.g. https://app.dev.kolr.ai/api, https://app.kolr.ai/api
}

const envSchema = z.object({
  API_BASE_URL: z.string(),
  APP_URL: z.string(),
  DEVELOPMENT_MODE: z.boolean(),
  LANDING_URL: z.string(),
  PUBLIC_CLIENT_URL: z.string(),
  GOOGLE_ADS_ID: z.string(),
  GOOGLE_ADS_ACCOUNT_CREATED_EVENT_ID: z.string(),
  GOOGLE_ADS_TRIAL_EVENT_ID: z.string(),
  GOOGLE_ADS_PURCHASE_SUBSCRIPTION_EVENT_ID: z.string(),
  STRIPE_PUBLISHABLE_KEY: z.string(),
  K6RSATELLITE_API_BASE_URL: z.string(),
  PUBLIC_K6RSATELLITE_API_BASE_URL: z.string(),
})

const env = envSchema.parse({
  APP_URL: mapAppUrl(),
  API_BASE_URL: mapApiBaseUrl(),
  DEVELOPMENT_MODE: isDevelopment,
  LANDING_URL: process.env.NEXT_PUBLIC_LANDING_URL,
  PUBLIC_K6RSATELLITE_API_BASE_URL:
    process.env.NEXT_PUBLIC_PUBLIC_K6RSATELLITE_API_BASE_URL,
  PUBLIC_CLIENT_URL: process.env.NEXT_PUBLIC_PUBLIC_CLIENT_URL,
  GOOGLE_ADS_ID: process.env.NEXT_PUBLIC_GOOGLE_ADS_ID,
  GOOGLE_ADS_ACCOUNT_CREATED_EVENT_ID:
    process.env.NEXT_PUBLIC_GOOGLE_ADS_ACCOUNT_CREATED_EVENT_ID,
  GOOGLE_ADS_TRIAL_EVENT_ID: process.env.NEXT_PUBLIC_GOOGLE_ADS_TRIAL_EVENT_ID,
  GOOGLE_ADS_PURCHASE_SUBSCRIPTION_EVENT_ID:
    process.env.NEXT_PUBLIC_GOOGLE_ADS_PURCHASE_SUBSCRIPTION_EVENT_ID,
  STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  K6RSATELLITE_API_BASE_URL: process.env.NEXT_PUBLIC_K6RSATELLITE_API_BASE_URL,
})

export default env

import env from '@/utils/env'
import { isClient } from '@/utils/is-client'

export const parseDomainByHost = (host: string): string => {
  return host.split('.').slice(-2).join('.')
}

// TODO: refactor after dev/staging domain change
const getDomain = (): string => {
  if (env.DEVELOPMENT_MODE) {
    return 'localhost'
  }

  return isClient() ? parseDomainByHost(window.location.host) : '/'
}

export default getDomain

import Script from 'next/script'
import { FunctionComponent } from 'react'
import {
  createGlobalStyle,
  css,
  FlattenSimpleInterpolation,
} from 'styled-components'
import ZeusEnvironment from '@/constants/zeus-environment'
import { useI18n } from '@/hooks/use-i18n'
import { inContextPseudoLanguage } from '@/i18n/config'

const CrowdinStyle = createGlobalStyle<{ enableCrowdin?: boolean }>`
    ${({ enableCrowdin }): FlattenSimpleInterpolation =>
      enableCrowdin
        ? css`
            [class*='jipt_'] {
              // ant-design 的按鈕裡面的 span 有 pointer-events: none，導致 hover 上去沒辦法顯示 crowdin 的 ui
              pointer-events: all !important;

              // https://www.notion.so/ikala/Prod-Crowdin-35bcff78d8174383967d1326dbca0ed8?pvs=4
              position: relative;
              z-index: 1;
            }
          `
        : css`
            [class*='jipt-'],
            [id*='jipt-'] {
              // 如果已經初始化 crowdin，在不使用 crowdin 的時候，把已經初始化的 crowdin 的 ui 隱藏
              &:not(.jipt-no-scroll):not(.jipt-highlight) {
                display: none !important;
              }
            }
            html.jipt-no-scroll {
              overflow: auto !important;
            }
          `}
`

const InContext: FunctionComponent = () => {
  const { locale } = useI18n()
  const enableCrowdinUi = locale === inContextPseudoLanguage

  if (process.env.NEXT_PUBLIC_ENVIRONMENT === ZeusEnvironment.Production) {
    return null
  }

  return (
    <>
      {/* 這倆 Script 裡面的的 code 來自 crowdin 官方 https://crowdin.com/project/ikala-project1/tools/in-context */}
      <Script src='//cdn.crowdin.com/jipt/jipt.js' />
      <Script id='crowdin'>
        var _jipt = []; _jipt.push([&#39;project&#39;,
        &#39;ikala-project1&#39;]);
      </Script>
      <CrowdinStyle enableCrowdin={enableCrowdinUi} />
    </>
  )
}

export default InContext

import { CurrencyCode, netSuite } from '@buggy/shared'
import { AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import { get } from 'lodash-es'
import { useDispatch } from 'react-redux'
import { useAsyncFn } from 'react-use'
import { useQueryParam, useQueryParams } from 'use-query-params'
import { UserAPITagType } from '@/api/api'
import { AppContext } from '@/api/executor/app-context'
import { gtagApi } from '@/api/gtag/gtag-api'
import { pipeDriveRegisterEvent } from '@/api/pipe-drive'
import { userApi } from '@/api/user-api'
import { CookieName } from '@/constants/cookie-name'
import { CountryCode } from '@/constants/country-currency'
import ZeusEnvironment from '@/constants/zeus-environment'
import { useSelector } from '@/hooks/use-selector'
import {
  cacheJWT,
  cacheLoginTime,
  login,
  loginFailed,
  loginSuccess,
  logout,
  State as MeState,
  register,
  registerFailed,
  registerSuccess,
} from '@/store/me'
import { LoginRequest, RegisterResponse } from '@/types/api-v1-types'
import { UserSchema } from '@/types/schema/user-schema'
import { ampli } from '@/utils/ampli'
import { getSSOPlatform } from '@/utils/get-sso-platform'
import sendIndividualAccountCreatedEvent from '@/utils/tracking/send-individual-account-created-event'
import { NonNullStringParam } from '@/utils/use-query-params'

const statusCode = {
  401: 'error:login_email_psw_fail',
  403: 'account:login_suspended_msg',
}

/**
 * @deprecated migrate to RTK
 */
export const hooks = {
  /**
   * 依設計，登出在前端瀏覽器總是視為成功。
   *
   * 登出功能其實會打 API，在 API.finally 前端總是會主動清除 cookies 並在瀏覽器 notify 成功。
   *
   * 然而 API 可能會因為 cookies(userToken) 過期導致 401，前端這時採取 WARNING 即可。
   *
   * 因為其實清 cookies 這樣對於使用者，就已經等於登出了。
   */
  useLogin(): {
    fn(data: LoginRequest): Promise<UserSchema>
    loginState: MeState
  } {
    const dispatch = useDispatch()
    const [trial_code] = useQueryParam('code', NonNullStringParam)

    const loginState = useSelector((state) => state.me)

    return {
      fn(data): Promise<UserSchema> {
        dispatch(login())

        return AppContext.ApiExecutor.login({
          ...data,
          trial_code,
        })
          .then((response) => {
            const { token, createTime } = response.data
            Cookies.set(CookieName.IsSingleLogin, 'false')
            dispatch(loginSuccess())
            dispatch(cacheJWT(token))
            dispatch(cacheLoginTime(createTime))

            ampli.loginSuccess({ type: 'email' })

            return response
          })
          .catch((error) => {
            const errorMessageKey = get(
              statusCode,
              error.response.status,
              'error:login_email_psw_fail',
            )
            dispatch(loginFailed(errorMessageKey))

            ampli.loginFail({ type: 'email' })
          })
          .then(() => AppContext.ApiExecutor.getUserInfo())
      },
      loginState,
    }
  },
  useLogoutFn: (): readonly [
    any, //StateFromFunctionReturningPromise<() => Promise<AxiosResponse<any, any>>>,
    () => Promise<AxiosResponse<any, any>>,
  ] => {
    const dispatch = useDispatch()

    const [logoutStatus, logoutFn] = useAsyncFn(() => {
      return AppContext.ApiExecutor.logout()
        .catch((error) => {
          console.warn(error.message)
          return error
        })
        .finally(() => {
          dispatch(logout())
        })
    })

    return [logoutStatus, logoutFn] as const
  },
  useRegister(): {
    normalRegister(data: any): Promise<AxiosResponse<RegisterResponse, any>>
    socialLogin(data: any): Promise<UserSchema>
    state: MeState
  } {
    const [{ from, code: trial_code }] = useQueryParams({
      from: NonNullStringParam,
      code: NonNullStringParam,
    })

    const dispatch = useDispatch()
    const state = useSelector((_state) => _state.me)

    return {
      normalRegister(data): Promise<AxiosResponse<RegisterResponse, any>> {
        dispatch(register())
        const channel = Cookies.get(CookieName.Channel)
        const payload = channel
          ? {
              ...data,
              channel,
            }
          : data

        return AppContext.ApiExecutor.register({
          ...payload,
          trial_code,
        })
          .then((response) => {
            // 註冊後清除 RTK 那邊的快取，以防其他元件拿到尚未註冊完成前的 User，see: https://gitlab.corp.ikala.tv/kolradar/buggy/-/merge_requests/715
            dispatch(
              userApi.util.invalidateTags([
                UserAPITagType.UserStatus,
                UserAPITagType.UserInfo,
              ]),
            )
            const { token, createTime } = response.data
            const user = jwt.decode(token) as UserSchema
            dispatch(registerSuccess())
            dispatch(cacheJWT(token))
            dispatch(cacheLoginTime(createTime))
            gtagApi.sendTo('lonZCITZwowBEO67rPQC')

            ampli.accountCreated({
              type: 'email',
              registeredUserType: user.type,
              from,
            })

            sendIndividualAccountCreatedEvent({
              type: 'email',
              userType: user.type,
              from,
            })

            ampli.loginSuccess({ type: 'email' })

            fbq(
              'trackCustom',
              `Register (${user.type === 'ad' ? 'AD' : 'KOL'})`,
            )

            if (payload.type !== 'kol') {
              if (payload.country === CountryCode.JP) {
                pipeDriveRegisterEvent(
                  { name: payload.company },
                  {
                    phone: payload.phone,
                    name: payload.username,
                    email: payload.email,
                  },
                  { source: `KOL Radar 註冊 ${payload.country}` },
                )
              } else {
                netSuite.create(
                  {
                    companyName: payload.company,
                    email: payload.email,
                    contactName: payload.username,
                    contactJobTitle: payload.jobTitle,
                    contactPhone: payload.phone,
                    country: payload.country,
                    currency: ((): CurrencyCode => {
                      if (payload.country === CountryCode.TW) {
                        return CurrencyCode.TWD
                      }
                      return CurrencyCode.USD
                    })(),
                    category: payload.industry,
                    source: 'KOL|註冊',
                  },
                  process.env.NEXT_PUBLIC_ENVIRONMENT !==
                    ZeusEnvironment.Production,
                )
              }
            }

            Cookies.remove(CookieName.Channel, { path: '/' })

            return response
          })
          .catch((error) => {
            ampli.accountCreatingFailed({
              type: 'email',
            })

            dispatch(registerFailed(error.response.status))
            return error
          })
      },
      socialLogin(data): Promise<UserSchema> {
        dispatch(register())
        const channel = Cookies.get(CookieName.Channel)
        const payload = channel
          ? {
              ...data,
              channel,
            }
          : data
        const ssoPlatform = getSSOPlatform(payload.oAuthType)

        return AppContext.ApiExecutor.socialLogin({
          ...payload,
          trial_code,
        })
          .then((response) => {
            const { token, createTime, createAccount } = response.data
            dispatch(loginSuccess())
            dispatch(cacheJWT(token))
            dispatch(cacheLoginTime(createTime))
            if (createAccount) {
              ampli.verifyEmailSuccessfully({ method: 'sso', ssoPlatform })
            }

            // KOL FB SSO with permissions
            if (payload.type !== 'ad' && data.userID) {
              AppContext.ApiExecutor.setFacebookAuthToken({
                accessToken: data.token,
                userID: data.userID,
              })
            }

            ampli.loginSuccess({ type: 'social', ssoPlatform })

            Cookies.set(CookieName.IsSingleLogin, 'true')
            return response
          })
          .catch((error) => {
            const { CreateAccountFailed } = error.response.data ?? {}
            if (CreateAccountFailed) {
              ampli.accountCreatingFailed({ type: 'social' })
            } else {
              ampli.loginFail({ type: 'social', ssoPlatform })
            }
            dispatch(registerFailed(error.response.status))
          })
          .then(() => AppContext.ApiExecutor.getUserInfo())
      },
      state,
    }
  },
}

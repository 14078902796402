import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { CookieName } from '@/constants/cookie-name'
import LocalStorageKeys from '@/constants/localstorage-keys'
import SessionStorageKeys from '@/constants/session-storage-keys'
import { I18nId } from '@/i18n/config'
import getDomain from '@/utils/get-domain'

export interface State {
  error: Error | number | null
  jwt: string | null
  loading: boolean
  loginError: I18nId | null
  loginTime: string | null
}

const initialState: State = {
  error: null,
  jwt: null,
  loading: false,
  loginError: null,
  loginTime: null,
}

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    cacheJWT: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload
      localStorage.setItem(LocalStorageKeys.Jwt, action.payload)
      Cookies.set(CookieName.UserToken, action.payload, {
        domain: getDomain(),
        path: '/',
        secure: true,
      })
    },
    cacheLoginTime: (state, action: PayloadAction<string>) => {
      state.loginTime = action.payload
      localStorage.setItem(LocalStorageKeys.LoginTime, action.payload)
      Cookies.set(CookieName.LoginTime, action.payload, { path: '/' })
    },
    login: (state) => {
      state.loading = true
    },
    register: (state) => {
      state.loading = true
    },
    loginSuccess: (state) => {
      state.loading = false
      state.loginError = null
    },
    registerSuccess: (state) => {
      state.loading = false
      state.error = null
    },
    loginFailed: (state, action: PayloadAction<I18nId | null>) => {
      state.loading = false
      state.loginError = action.payload
    },
    registerFailed: (state, action: PayloadAction<number>) => {
      state.loading = false
      state.error = action.payload
    },
    logout: (state) => {
      state.loading = false
      state.jwt = null
      state.loginTime = null
      state.error = null
      state.loginError = null

      Cookies.remove(CookieName.UserToken, { domain: getDomain(), path: '/' })
      Cookies.remove(CookieName.LoginTime, { path: '/' })
      localStorage.removeItem(LocalStorageKeys.Jwt)
      localStorage.removeItem(LocalStorageKeys.LoginTime)
      sessionStorage.removeItem(SessionStorageKeys.ShowMailConfirmedModal)
    },
  },
})

export const {
  cacheJWT,
  cacheLoginTime,
  login,
  register,
  loginSuccess,
  registerSuccess,
  loginFailed,
  registerFailed,
  logout,
} = meSlice.actions
export default meSlice.reducer
